<template>
  <div class="login">
    <span class="systitle">PBX録音再生 For WEB</span>
    <h4>version {{ version }}</h4>
    <form class="loginform" @submit.prevent="login()">
      <div class="loginuser">
        ユーザーID
        <input
          type="text"
          placeholder="ユーザーID"
          v-model="username"
          class="intext"
          required
        />
      </div>
      <div class="loginpass">
        パスワード
        <input
          type="password"
          placeholder="パスワード"
          v-model="password"
          class="intext"
          required
        />
      </div>
      <button class="loginbtn">ログイン</button>
    </form>
    <small>&copy; {{ year }} MOBILECREATE</small>
  </div>
</template>

<script>
/* eslint-disable */
import packageJson from "../../package.json"
import moment from "moment";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      version: packageJson.version,
      year: moment().format('YYYY'),
    };
  },
  methods: {
    login() {
      this.$cognito
        .login(this.username, this.password)
        .then((result) => {
          (async () => {
            this.$store.commit("resetAll");
            //stoireに一式格納
            this.$store.commit("setIdToken", result.auth.idToken);
            this.$store.commit("setAccessToken", result.auth.accessToken);
            this.$store.commit("setRefreshToken", result.auth.refreshToken);
            this.$store.commit("setExp", result.auth.exp);
            this.$store.commit("setCompanyid", result.attr.companyid);
            this.$store.commit("setCompanyname", result.attr.companyname);
            this.$store.commit("setRole", result.attr.role);
            this.$store.commit("setSub", result.attr.sub);

            if (
              this.$store.getters.idToken == null ||
              this.$store.getters.idToken == "" ||
              this.$store.getters.accessToken == null ||
              this.$store.getters.accessToken == "" ||
              this.$store.getters.refreshToken == null ||
              this.$store.getters.refreshToken == ""
            ) {
              await this.$confirm.alert("認証サーバでエラーが発生しているため、ログインが失敗しました。")
              this.$store.commit("resetAll");
              return;
            }

            if (
              this.$store.getters.companyid == null ||
              this.$store.getters.companyid == "" ||
              this.$store.getters.role == null ||
              this.$store.getters.role == ""
            ) {
              //会社名はなくてもヨシとする
              await this.$confirm.alert("会社情報が取得できないため、ログインが失敗しました。")
              this.$store.commit("resetAll");
              return;
            }
            this.$router.push("/PbxMonitor");
          })()
          
        })
        .catch((err) => {
          (async () => {
            await this.$confirm.alert("ログインに失敗しました。認証情報が間違っている可能性があります。")
          })()
        });
    },
  },
  components: {
    
  },
};
/* eslint-enable */
</script>

<style>
h4 {
  margin: 0px;
}
div.login {
  display: inline-block;
  height: 30vh;
  width: 30vw;
  position: absolute; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
span.systitle{
  font-size: 45px;
  font-weight: bold;
}
form.loginform {
  width: 100%;
  height: 90%;
  border: 1px solid #000000;
  position: relative;
}
div.loginuser {
  display: inline-block;
  height: 15%;
  width: 100%;
  position: absolute; 
  top: 30px;
  right: 0;
  bottom: 0;
  left: 0;
}
div.loginpass {
  display: inline-block;
  height: 15%;
  width: 100%;
  position: absolute; 
  top: 90px;
  right: 0;
  bottom: 0;
  left: 0;
}
button.loginbtn {
  display: inline-block;
  height: 20%;
  width: 50%;
  position: absolute; 
  top: 150px;
  right: 0;
  bottom: 0;
  left: 140px;
  font-size: 25px;
  font-weight: bold;
}
input.intext {
    width: 250px;
    height: 25px;
    font-size: 20px;
}
</style>