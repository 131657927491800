<template>
  <div id="overlay">
    <img src="../assets/Loading.gif">
  </div>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    msg: String,
  },
};
</script>

<style>
#overlay {
  z-index:1;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

#content {
  z-index:10;
  
}

</style>