<template>
  <div class="searchzone">
    <form class="searchform" @submit.prevent="getMonitorData()">
      <div class="searchformdiv">
        <span>録音日時(start):</span>
        <br>
        <input id="startdt" type="datetime-local" v-model="startdt" :min="start" @input="startdt = $event.target.value">
      </div>
      <div class="searchformdiv">
        <span>録音日時(end):</span>
        <br>
        <input id="enddt" type="datetime-local" v-model="enddt" :min="start" @input="enddt = $event.target.value">
      </div>
      <div class="searchformdiv">
        <span>電話番号(ハイフンなし):</span>
        <br>
        <input id="tel" type="text" v-model="callerid">
      </div>
      <div class="searchformdiv">
        <span>区分:</span>
        <br>
        <select id="typeselect" v-model="calltype">
          <option selected value="0">指定なし</option>
            <option v-for="type in typelist" :value="type.id" :key="type.id">
                {{ type.name }}
            </option>
        </select>
      </div>
      <div class="searchformdiv">
        <button id="searchbtn">検 索</button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import bus from '../bus.js'
import DateTime from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";

export default {
  data() {
    return {
      showContent: false,
      msg: "",
      typelist: [
        {id: 1, name: '着信'},
        {id: 2, name: '発信'},
      ],
      startdt: moment().add(-1, 'hours').format("YYYY-MM-DDTHH:mm"),
      enddt: moment().format("YYYY-MM-DDTHH:mm"),
      companyid: this.$store.getters.companyid,
      callerid: null,
      calltype: null,
    };
  },
  components: {
    DateTime,
  },
  methods: {
    async getMonitorData() {
      //値のパラメータチェック&作成
      let reqParams = {}
      const s = moment(this.startdt)
      const e = moment(this.enddt)
      if (e.diff(s) <= 0) {
        await this.$confirm.alert("日時はendがstartより以前にならないように指定してください。")
        return
      }

      let _type = null
      if (this.calltype == 1) {
        _type = 'IN'
      } else if (this.calltype == 2) {
        _type = 'OUT'
      }

      let companyId = null
      if (this.companyid != '9999') {
        //9999はMC保守用で、全取りのため指定しない
        companyId = this.companyid
      }

      //callerid に非通知検索対応
      let callerid = null
      if (this.callerid && (this.callerid == '非' || this.callerid == '非通' || this.callerid == '非通知')) {
        callerid = 'anonymous'
      } else {
        callerid = this.callerid
      }

      reqParams.startdt = this.startdt
      reqParams.enddt = this.enddt
      reqParams.companyid = companyId
      reqParams.callerid = callerid
      reqParams.calltype = _type

      //パラメータをTableMinotorコンポーネントに送り、API実行 & 描画してもらう
      bus.$emit('repaintTable', reqParams)
    },
  },
  computed: {
    now() {
      const now = moment();
      return now.format("YYYY-MM-DDTHH:mm");
    },
    lastOneHour() {
      const last = moment().add(-1, 'hours');
      return last.format("YYYY-MM-DDTHH:mm");
    },
    start() {
      const start = moment().add(-1, 'months');
      return start.format("YYYY-MM-DDTHH:mm");
    },
    end() {
      const end = moment().add(1, 'hours');
      return end.format("YYYY-MM-DDTHH:mm");
    },
  },
};

/* eslint-enable */
</script>

<style>
div.searchzone {
  height: 100%;
  width: 20%;
  float: left;
}
form.searchform {
  height: 50%;
}
div.searchformdiv {
  height: 20%;
  width: 90%;
  text-align: left;
  padding-left: 20px;
}
#startdt, #enddt, #tel {
  height: 25px;
  width: 250px;
  font-size: 20px;
}
#typeselect {
  height: 35px;
  width: 120px;
  font-size: 20px;
}
#searchbtn {
  height: 50px;
  width: 190px;
  font-size: 30px;
  font-weight: bold;
}
</style>
