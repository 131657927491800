<template>
  <div id="createcompanyzone">
    <form id="createcompanyform" @submit.prevent="regist()">
      <div class="nonezone"></div>
      <table class="formtable">
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <td>会社ID</td>
          <td>
            <input
              type="text"
              placeholder="会社ID"
              class="intext"
              v-model="companyid"
              required
            />
          </td>
          <td>4桁の半角数字</td>
        </tr>
        <tr>
          <td>会社名</td>
          <td>
            <input
              type="text"
              placeholder="会社名"
              class="intext"
              v-model="companyname"
              required
            />
          </td>
          <td>64字まで</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <button class="registbtn">登録</button>
          </td>
          <td></td>
        </tr>
      </table>
    </form>
    <Loading v-show="showLoading"/>
  </div>
</template>

<script>
/* eslint-disable */
import Loading from "@/components/Loading.vue";

export default {
  data() {
    return {
      showLoading: false,
      companyid: "",
      companyname: "",
    };
  },
  methods: {
    async regist() {
      //各種パラメータチェック
      //companyidが半角4桁数字であるかどうか
      if (!this.companyid.match('^[0-9]{1,4}$')) {
          await this.$confirm.alert("会社IDは半角4桁数字である必要があります。")
          return;
      }
      //companynameが64字であるかどうか
      if (this.companyname.length > 64) {
          await this.$confirm.alert("会社名は64字までです。")
          return;
      }
      this.showLoading = true
      const params = {
        companyid: this.companyid,
        companyname: this.companyname,
      }
      const res = await this.$api.postCompany(params, this.$store.getters.idToken)
      this.showLoading = false
      if (!res.success && res.status == 400) {
        //画面で事前チェックしてるのであり得ないが一応
        await this.$confirm.alert("会社IDもしくは会社名がありません。")
      }　else if (!res.success && res.status == 500) {
        await this.$confirm.alert("会社IDに重複があるか、サーバでエラーが発生しました。")
      } else if (!res.success) {
        await this.$confirm.alert("サーバでエラーが発生しました。")
      } else {
        await this.$confirm.alert("登録しました。")
        this.companyid = ''
        this.companyname = ''
      }
      
    },
  },
  components: {
    Loading,
  },
};

/* eslint-enable */
</script>

<style>
div#createcompanyzone {
  height: inherit;
  width: inherit;
}
form#createcompanyform {
  height: 100%;
  width: 100%;
}
div.nonezone {
  height: 20%;
  width: 100%;
}
table.formtable {
  border: none;
  height: 25%;
  width: 25%;
  margin: 0 auto;
}
button.registbtn {
  width: 100px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
}
</style>