var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablezone"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
          enabled: true,
          placeholder: 'キーワード入力で、区分、発信者番号、録音日時などのいづれかをフィルタリングできます。',
      },"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 14,
          position: 'bottom',
          perPageDropdown: [6, 8, 10, 12],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: '次',
          prevLabel: '前',
          rowsPerPageLabel: '行数',
          ofLabel: 'of',
          pageLabel: 'ページ',
          allLabel: 'All',
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'delbtn')?_c('span',[_c('button',{staticClass:"tableStyle btnDelete",on:{"click":function($event){return _vm.evDeleteBtn(props.row)}}},[_vm._v("削除")])]):_vm._e()]}}])}),_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoading),expression:"showLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }