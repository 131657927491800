<template>
  <div id="accountupdatezone">
      <div id="displayAccount">
          <div id="conpanyList" v-show="isShowConpanyListZone">
            <select id="companyselect" @change="getSelectBox">
              <option selected value=""></option>
              <option v-for="c in companyList" :value="c.companyid + '-' + c.companyname " :key="c.companyid">
                  {{ c.companyname }} - {{ c.companyid }}
              </option>
            </select>
            <br>
            <span id="companydisplay" v-show="showCompanySpan">会社ID: {{ this.companyid }}</span>
          </div>
          <div id="userList" v-show="isShowUserListZone">
            <span>認証の編集は対象の選択列のラジオボタンを選択して<br>「編集」ボタンを押下します。</span>
            <vue-good-table 
                :columns="columns" 
                :rows="rows" >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'selectuser'">
                        <input type="radio" @change="evChangeRadio(props.row)" name="selectrow" v-model="ratio" :value="props.row.originalIndex" :disabled="disabledRdio"/>
                    </span>
                </template>
            </vue-good-table>
          </div>
      </div>
      <div id="updateAccount">
          <div id="updateBtnArea" v-show="isShowUpdateBtnAreaZone">
            <button class="updatebtn" :disabled="editBtnDisabled" @click="evClickEdit">編集</button>
            <button class="updatebtn" :disabled="addBtnDisabled" @click="evClickAdd">追加</button>
          </div>
          <div id="updateInputArea" v-show="isShowUpdateInputAreaZone">
            <form id="updateInputfrom" @submit.prevent="evClickRegist" @reset.prevent="evClickCancel">
              <table class="userinfotable">
                <tr>
                  <th class="userinfotable_title"></th>
                  <th class="userinfotable_input"></th>
                  <th class="userinfotable_sub"></th>
                </tr>
                <tr>
                  <td>ユーザID</td>
                  <td>
                    <input
                      type="text"
                      placeholder="ユーザID"
                      class="inputtext"
                      v-model="userid"
                      :disabled="useridTxtDisabled"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <input 
                      type="checkbox" 
                      name="updatepass" 
                      v-model="passflg"
                      :disabled="updateChkDisabled"
                      @change="evChangePassUpdateChk"
                    />パスワードを更新する。
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>新しいパスワード</td>
                  <td>
                    <input
                      type="password"
                      placeholder="新しいパスワード"
                      class="inputtext"
                      v-model="newpass"
                      :disabled="newpassTxtDisabled"
                    />
                  </td>
                  <td>※パスワードは半角英字の大文字と半角小文字、半角数字を<br>含めた6文字以上で構成される必要があります。</td>
                </tr>
                <tr>
                  <td>パスワードの確認</td>
                  <td>
                    <input
                      type="password"
                      placeholder="パスワードの確認"
                      class="inputtext"
                      v-model="retypepass"
                      :disabled="repassTxtDisabled"
                    />
                  </td>
                  <td>※推測しにくいパスワードを設定してください。</td>
                </tr>
                <tr>
                  <td>ログイン種別</td>
                  <td>
                    <select class="typeselect" v-model="logintype" :disabled="typeSelDisabled">
                      <option selected value=""></option>
                        <option v-for="type in loginTypeList" :value="type.id" :key="type.id">
                            {{ type.name }}
                        </option>
                    </select>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>削除</td>
                  <td>
                    <input 
                      type="checkbox" 
                      name="delete" 
                      v-model="delflg"
                      :disabled="deleteChkDisabled"
                    />する
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <input type="submit" class="updatebtn" :disabled="redgistBtnDisabled" value="登録">
                    <input type="reset" class="cancelbtn" :disabled="cancelBtnDisabled" value="リセット">
                  </td>
                </tr>
              </table>
            </form>
          </div>
      </div>
      <Loading v-show="showLoading"/>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import "vue-good-table/dist/vue-good-table.css"
import { VueGoodTable } from "vue-good-table"
import Loading from "@/components/Loading.vue";

export default {
  data() {
    return {
      showLoading: false,
      showCompanySpan: false,
      msg: "",
      companyid: '',
      companyname: '',
      companyList: null,
      columns: [
        { label: "選択", field: "selectuser", sortable: false, width: '1px', tdClass: 'tdCenterStyle',},
        { label: "削除", field: "delflg", formatFn: this.delFlgFn, sortable: false, width: '5px', tdClass: 'tdCenterStyle',},
        { label: "種別", field: "role", formatFn: this.roleFn, sortable: false, width: '50px',},
        { label: "ユーザ", field: "userid", sortable: false, width: '100px',},
      ],
      rows: [],
      ratio: null,
      disabledRdio: false,
      userid: '',
      passflg: false,
      newpass: '',
      retypepass: '',
      logintype: '',
      delflg: false,
      loginTypeList : [
        {id: 'admin', name: '管理者'},
        {id: 'user', name: '一般'},
      ],
      isShowConpanyListZone: false,
      isShowUserListZone: false,
      isShowUpdateBtnAreaZone: false,
      isShowUpdateInputAreaZone: false,
      selectedrow: {userid: null, role: null, delflg: null},
      editBtnDisabled: true,
      addBtnDisabled: false,
      useridTxtDisabled: true,
      updateChkDisabled: true,
      newpassTxtDisabled: true,
      repassTxtDisabled: true,
      typeSelDisabled: true,
      deleteChkDisabled: true,
      redgistBtnDisabled: true,
      cancelBtnDisabled: true,
      updateMode: '',
    };
  },
  components: {
    Loading,
    VueGoodTable,
  },
  async created() {
    //直でこのパスに来てもトークンが不正であればログイン画面に戻るページに遷移
    if (
      this.$store.getters.idToken == null ||
      this.$store.getters.idToken == "" ||
      this.$store.getters.accessToken == null ||
      this.$store.getters.accessToken == "" ||
      this.$store.getters.refreshToken == null ||
      this.$store.getters.refreshToken == "" ||
      this.$store.getters.companyid == null ||
      this.$store.getters.companyid == "" ||
      this.$store.getters.role == null ||
      this.$store.getters.role == ""
    ) {
      this.$router.push("/Redirect").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
      return
    }
    //トークン有効期限が切れているor切れる10分前もNG
    const now = moment();
    const exp = moment(this.$store.getters.exp, "X");
    if (exp.diff(now, "minutes") < 10) {
      this.$router.push("/Redirect").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
      return
    }
    //入力情報欄各種はdisableにする
    this.inputDispaly(0)
    if (this.$store.getters.role == 'super') {
      this.displayZone(1)
      this.showLoading = true
      await this.setCompanyVal()
      this.showLoading = false
    } else {
      this.displayZone(0)
      //管理者・一般は自分のログイン時の会社IDと名前が正になる
      this.companyid = this.$store.getters.companyid
      this.companyname = this.$store.getters.companyname
      this.showLoading = true
      await this.setUserListProc(this.$store.getters.companyid)
      this.showLoading = false
    }
    
  },
  methods: {
    async setCompanyVal() {
      const getCompanyList = await this.$api.getCompany(null, this.$store.getters.idToken);
      if (getCompanyList.success && getCompanyList.status == 200 && getCompanyList.items != null) {
        this.companyList = getCompanyList.items.items
      } else {
        await this.$confirm.alert("サーバにてエラーが発生しました。お手数ですが、再ログインしてください。")
        this.$router.push("/").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
        return;
      }
    },
    async getSelectBox(val) {
      //会社に紐づくユーザ一覧をテーブルに表示する.。全権限はセレクトボックス選択から開始。
      //会社IDを画面に表示する
      const s = val.target.value.split('-')
      this.companyid = s[0] || ''
      this.companyname = s[1] || ''
      if (s[0] && s[1]) {
        this.showCompanySpan = true
        this.displayZone(2)
      } else {
        //未選択に戻した
        this.showCompanySpan = false
        this.displayZone(1)
        return
      }

      this.showLoading = true
      await this.setUserListProc(this.companyid)
      this.showLoading = false
    },
    async setUserListProc(companyid) {
      //会社IDからそのユーザ情報を取得する
      this.rows = []
      const params = { companyid: companyid }
      const getUserList = await this.$api.getUserList(params, this.$store.getters.idToken)
      if (!getUserList || !getUserList.success || getUserList.status != 200) {
        await this.$confirm.alert("ユーザ取得時にサーバにてエラーが発生しました。お手数ですが、再ログインしてください。")
        this.$router.push("/").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
        return;
      }
      const userlist = getUserList.items.items
      userlist.forEach((v) => {
        let role = null
        v.Attributes.forEach((w) => {
          if (w.Name == 'custom:role') {
            role = w.Value
            return true
          }
        })
        const row = {
          userid: v.Username,
          role: role || 'user',
          delflg: (v.Enabled) ? '0' : '1',
        }
        this.rows.push(row)
      })
    },
    delFlgFn(val) {
      if (val == '1') {
        return '削'
      }
      return ''
    },
    roleFn(val) {
      if (val == 'super') {
        return '超管理者'
      } else if (val == 'admin') {
        return '管理者'
      } else {
        return '一般'
      }
    },
    //Event Proc
    evChangeRadio(prop) {
      this.selectedrow.userid = prop.userid
      this.selectedrow.role = prop.role
      this.selectedrow.delflg = prop.delflg
      this.editBtnDisabled = false
    },
    evClickEdit(prop) {
      this.inputDispaly(1)
      this.editBtnDisabled = true
      this.addBtnDisabled = true
      this.disabledRdio = true
    },
    evClickAdd(prop) {
      this.inputDispaly(2)
    },
    async evClickRegist(prop) {
      if (this.updateMode == 'edit') {
        //入力チェック
        if (this.passflg) {
          if (!this.newpass) {
            await this.$confirm.alert("パスワードを入力してください。")
            return
          }
          if (!/[a-z]/.test(this.newpass) || !/[A-Z]/.test(this.newpass) || !/[0-9]/.test(this.newpass)) {
            await this.$confirm.alert("パスワードは大文字、小文字、数字を含む6文字以上である必要があります。")
            return
          }
          if (!this.retypepass) {
            await this.$confirm.alert("確認用パスワードを入力してください。")
            return
          }
          if (this.newpass.length < 6) {
            await this.$confirm.alert("パスワードは6文字以上設定してください。")
            return
          }
          if (this.newpass !== this.retypepass) {
            await this.$confirm.alert("入力したパスワードが、確認用と一致しませんでした。")
            return
          }
          if (this.newpass.toUpperCase() === this.userid.toUpperCase()) {
            await this.$confirm.alert("ユーザIDとパスワードは同じにできません。")
            return
          }
        }

        const params = {
          userid: this.userid,
          companyid: this.companyid,
          password: this.newpass,
          delflg: this.delflg,
        }
        const updateUser = await this.$api.putUser(params, this.$store.getters.idToken)
        if (updateUser.success && updateUser.status == 200) {
          await this.$confirm.alert("ユーザを更新しました。")

          //更新完了後、フォーム等を初期化し、作成した分を含めてユーザ一覧を取得する
          this.inputDispaly(0)
          this.showLoading = true
          await this.setUserListProc(this.companyid)
          this.showLoading = false
        } else {
          await this.$confirm.alert("ユーザ更新中エラーが発生しました。")
        }

      } else {
        //入力チェック
        if (!this.userid) {
          await this.$confirm.alert("ユーザIDを入力してください。")
          return
        }
        if (!this.newpass) {
          await this.$confirm.alert("パスワードを入力してください。")
          return
        }
        if (!/[a-z]/.test(this.newpass) || !/[A-Z]/.test(this.newpass) || !/[0-9]/.test(this.newpass)) {
          await this.$confirm.alert("パスワードは大文字、小文字、数字を含む6文字以上である必要があります。")
          return
        }
        if (!this.retypepass) {
          await this.$confirm.alert("確認用パスワードを入力してください。")
          return
        }
        if (!this.logintype) {
          await this.$confirm.alert("ログイン種別を選択してください。")
          return
        }
        if (this.newpass.length < 6) {
          await this.$confirm.alert("パスワードは6文字以上設定してください。")
          return
        }
        if (this.newpass !== this.retypepass) {
          await this.$confirm.alert("入力したパスワードが、確認用と一致しませんでした。")
          return
        }
        if (this.newpass.toUpperCase() === this.userid.toUpperCase()) {
          await this.$confirm.alert("ユーザIDとパスワードは同じにできません。")
          return
        }
        const params = {
          userid: this.userid,
          companyname: this.companyname,
          companyid: this.companyid,
          password: this.newpass,
          role: this.logintype,
        }
        const createUser = await this.$api.postUser(params, this.$store.getters.idToken)
        if (createUser.success && createUser.status == 200) {
          await this.$confirm.alert("ユーザを作成しました。")

          //作成完了後、フォーム等を初期化し、作成した分を含めてユーザ一覧を取得する
          this.inputDispaly(0)
          this.showLoading = true
          await this.setUserListProc(this.companyid)
          this.showLoading = false
        } else {
          await this.$confirm.alert("ユーザ作成中エラーが発生しました。。")
        }
      }
    },
    evClickCancel(prop) {
      this.inputDispaly(0)
    },
    evChangePassUpdateChk(prop) {
      if (prop.target.checked) {
        this.inputDispaly(3)
      } else {
        this.inputDispaly(4)
      }
    },
    //input proc
    inputDispaly(mode) {
      if (mode == 0) {
        //全disable(初期状態)
        this.useridTxtDisabled = true
        this.updateChkDisabled = true
        this.newpassTxtDisabled = true
        this.repassTxtDisabled = true
        this.typeSelDisabled = true
        this.deleteChkDisabled = true
        this.redgistBtnDisabled = true
        this.cancelBtnDisabled = true
        this.editBtnDisabled = true
        this.addBtnDisabled = false
        this.disabledRdio = false
        this.updateMode = ''
      } else if (mode == 1) {
        //編集モード
        this.useridTxtDisabled = true
        this.updateChkDisabled = false
        this.newpassTxtDisabled = true
        this.repassTxtDisabled = true
        this.typeSelDisabled = true
        this.deleteChkDisabled = false
        this.redgistBtnDisabled = false
        this.cancelBtnDisabled = false
        this.editBtnDisabled = true
        this.addBtnDisabled = true
        this.disabledRdio = true
        this.updateMode = 'edit'
      } else if (mode == 2) {
        //追加モード
        this.useridTxtDisabled = false
        this.updateChkDisabled = true
        this.newpassTxtDisabled = false
        this.repassTxtDisabled = false
        this.typeSelDisabled = false
        this.deleteChkDisabled = true
        this.redgistBtnDisabled = false
        this.cancelBtnDisabled = false
        this.updateMode = 'add'
      } else if (mode == 3) {
        //更新チェック
        this.newpassTxtDisabled = false
        this.repassTxtDisabled = false
      } else if (mode == 4) {
        //更新非チェック
        this.newpassTxtDisabled = true
        this.repassTxtDisabled = true
      }
      this.inputSet(mode)
    },
    inputSet(mode) {
      if (mode == 0|| mode == 2) {
        //全disable(初期状態) + 追加モード
        this.userid = ''
        this.logintype = ''
        this.delflg = false
        this.passflg = false
        this.newpass = ''
        this.retypepass = ''
        this.ratio = null
      } else if (mode == 1) {
        //編集モード
        this.userid = this.selectedrow.userid
        this.logintype = this.selectedrow.role
        this.delflg = (this.selectedrow.delflg == '1') ? true : false
        this.passflg = false
        this.newpass = ''
        this.retypepass = ''
      }  else if (mode == 3 || mode == 4) {
        //更新(非)チェック
        this.newpass = ''
        this.retypepass = ''
      } 
    },
    //display Proc
    displayZone(mode) {
      if (mode == 0) {
        //管理者および一般初期状態
        this.isShowConpanyListZone = false
        this.isShowUserListZone = true
        this.isShowUpdateBtnAreaZone = true
        this.isShowUpdateInputAreaZone = true
      } else if (mode == 1) {
        //全権限初期状態
        this.isShowConpanyListZone = true
        this.isShowUserListZone = false
        this.isShowUpdateBtnAreaZone = false
        this.isShowUpdateInputAreaZone = false
      } else if (mode == 2) {
        //全権限会社選択時
        this.isShowConpanyListZone = true
        this.isShowUserListZone = true
        this.isShowUpdateBtnAreaZone = true
        this.isShowUpdateInputAreaZone = true
      }
    }
  }
};

/* eslint-enable */
</script>

<style>
div#accountupdatezone {
  font-size: 20px;
  height: inherit;
  width: inherit;
}
div#displayAccount {
  height: 100%;
  width: 35%;
  float: left;
}
div#conpanyList {
  margin: 9%;
  height: 10%;
  width: 100%;
  text-align: left;
}
#companyselect {
  margin: 15px;
  width: 250px;
  height: 28px;
  font-size: 17px;
}
#companydisplay {
  margin: 15px;
}
div#userList {
  height: 76%;
  width: 100%;
}
.tdCenterStyle {
  text-align: center;
}
div#updateAccount {
  height: 100%;
  width: 65%;
  float: left;
}
div#updateBtnArea {
  height: 7%;
  width: 100%;
  text-align: left;
}
.updatebtn {
  width: 70px;
  height: 40px;
  font-size: 20px;
  margin: 15px;
  font-weight: bold;
}
div#updateInputArea {
  height: 83%;
  width: 100%;
}
form#updateInputfrom {
  height: 100%;
  width: 100%;
}
table.userinfotable {
  border: none;
  height: 98%;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
th.userinfotable_title {
  width: 20%;
}
th.userinfotable_input {
  width: 30%;
}
th.userinfotable_sub {
  width: 50%;
}
input.inputtext {
  width: 260px;
  height: 25px;
  font-size: 20px;
}
select.typeselect {
  width: 180px;
  height: 30px;
  font-size: 20px;
}
.cancelbtn {
  width: 130px;
  height: 40px;
  font-size: 20px;
  margin: 15px;
  font-weight: bold;
}

</style>