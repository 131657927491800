<template>
  <div id="managezone">
    <div id="mainzone">
      <ul class="tabs">
        <li
          v-on:click="change('1')"
          v-bind:class="{ active: isActive === '1' }"
        >
          認証追加/更新
        </li>
        <li
          v-if="this.$store.getters.role == 'super'"
          v-on:click="change('2')"
          v-bind:class="{ active: isActive === '2' }"
        >
          会社作成
        </li>
      </ul>

      <ul class="contents">
        <li v-if="isActive === '1'">
          <AccountUpdate />
        </li>
        <li v-else-if="isActive === '2'">
          <CreateCompany />
        </li>
      </ul>
    </div>
    <div id="footer">
      <button id="manage_close" v-on:click="goBack()">戻る</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import AccountUpdate from "@/components/AccountUpdate.vue"
import CreateCompany from "@/components/CreateCompany.vue"

export default {
  data() {
    return {
      isActive: "1",
    };
  },
  components: {
    AccountUpdate,
    CreateCompany,
  },
  created() {
    
  },
  methods: {
    change: function (num) {
      this.isActive = num;
    },
    goBack() {
      this.$router.push("/PbxMonitor").catch((err) => {});
      return;
    },
  },
};

/* eslint-enable */
</script>

<style>
div#managezone {
  height: 100vh;
  width: 100vw;
}
div#mainzone {
  height: 93%;
  padding: 5px;
}
div#footer {
  height: 5%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 15px;
  text-align: right;
}
button#manage_close {
  width: 100px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.tabs {
  overflow: hidden;
}
.tabs li,
.tabs label {
  float: left;
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: 0.3s;
}
.tabs li:not(:first-child),
.tabs label:not(:first-of-type) {
  border-left: none;
}
.tabs li.active,
.tabs :checked + label {
  background-color: #000;
  border-color: #000;
  color: #fff;
  cursor: auto;
}
.contents {
  overflow: hidden;
  margin-top: -1px;
  width: inherit;
  height: inherit;
  border: 1px solid #ccc;
}
.contents li {
  height: 100%;
  width: 100%;
}
</style>