/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'pbxmonitor',
      storage: window.sessionStorage
    })
  ],
  state: {
    idToken: '',
    accessToken: '',
    refreshToken: '',
    exp: 0,
    companyid: '',
    companyname: '',
    role: '',
    sub: '',
    tokenTimer: null,
  },
  mutations: {
    setIdToken(state, idToken) {
      state.idToken = idToken
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setExp(state, exp) {
      state.exp = exp
    },
    setCompanyid(state, companyid) {
      state.companyid = companyid
    },
    setCompanyname(state, companyname) {
      state.companyname = companyname
    },
    setRole(state, role) {
      state.role = role
    },
    setSub(state, sub) {
      state.sub = sub
    },
    setTokenTimer(state, tokenTimer) {
      state.tokenTimer = tokenTimer
    },
    resetAll(state) {
      state.idToken = ''
      state.accessToken = ''
      state.refreshToken = ''
      state.exp = 0
      state.companyid = ''
      state.companyname = ''
      state.role = ''
      state.sub = ''
      state.tokenTimer = null
    },
  },
  getters: {
    idToken: state => state.idToken,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    exp: state => state.exp,
    companyid: state => state.companyid,
    companyname: state => state.companyname,
    role: state => state.role,
    sub: state => state.sub,
    tokenTimer: state=> state.tokenTimer,
  },
  action: {

  },
})
/* eslint-enable */