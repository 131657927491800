<template>
    <div>
      <h2>セッションの有効期限が切れています。</h2>
      <h3>5秒後にログインページにリダイレクトします。お手数ですが再ログインしてください。</h3>
    </div>
</template>

<script>
/* eslint-disable */
export default {
  data(){
    return{
      
    }
  },
  mounted () {
    setTimeout(() => {
      this.$router.push('/')
    }, 5000);
  }
}
/* eslint-enable */
</script>