/* eslint-disable */
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog);

export default class Confirm {
    configure() {
        this.promptBtn = {
            okText: 'はい',
            cancelText: 'キャンセル',
            html: true,
        }
    }

    static install = (Vue, options) => {
        Object.defineProperty(Vue.prototype, '$confirm', {
            get() { return this.$root._confirm }
        })

        Vue.mixin({
            beforeCreate() {
                if (this.$options.confirm) {
                    this._confirm = this.$options.confirm
                    this._confirm.configure()
                }
            }
        })
    }

    alert(msg) {
        return new Promise((resolve) => {
            Vue.dialog.alert({
                body: msg
            }, this.promptBtn)
            .then(function(dialog) {
                resolve()
            });
        })
    }

    confirm(msg) {
        return new Promise((resolve) => {
            Vue.dialog.confirm({
                body: msg
            }, this.promptBtn)
            .then(function(dialog) {
                resolve(1)
            })
            .catch(function() {
                resolve(0)
            });
        })
    }
}

/* eslint-enable */