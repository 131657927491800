<template>
  <div class="tablezone">
    <vue-good-table 
        :columns="columns" 
        :rows="rows" 
        :search-options="{
            enabled: true,
            placeholder: 'キーワード入力で、区分、発信者番号、録音日時などのいづれかをフィルタリングできます。',
        }"
        :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 14,
            position: 'bottom',
            perPageDropdown: [6, 8, 10, 12],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: '次',
            prevLabel: '前',
            rowsPerPageLabel: '行数',
            ofLabel: 'of',
            pageLabel: 'ページ',
            allLabel: 'All',
        }"
        >
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'delbtn'">
                <button @click="evDeleteBtn(props.row)" class="tableStyle btnDelete">削除</button>
            </span>
        </template>
    </vue-good-table>
    <Loading v-show="showLoading"/>
  </div>
</template>

<script>
/* eslint-disable */
import bus from '../bus.js'
import "vue-good-table/dist/vue-good-table.css"
import { VueGoodTable } from "vue-good-table"
import Loading from "@/components/Loading.vue";
import moment from "moment"

export default {
  data() {
    return {
      showLoading: false,
      columns: [
        { label: "企業ID", field: "companyid", sortable: false, hidden: this.displayCompayidProc(), width: '70px', tdClass: 'tableStyle',globalSearchDisabled: this.displayCompayidProc(),},
        { label: "区分", field: "calltype", formatFn: this.calltypeFn, sortable: false, width: '60px', tdClass: 'tableStyle',},
        { label: "発信者番号", field: "callerid", sortable: false, formatFn: this.calleridFn, width: '100px', tdClass: 'tableStyle',},
        { label: "録音日時", field: "monitordt", type: "date", dateInputFormat: "yyyy-MM-dd HH:mm:ss", dateOutputFormat: "yyyy-MM-dd HH:mm:ss", sortable: true, width: '120px', tdClass: 'tableStyle',},
        { label: "操作", field: "filelocation", html: true, sortable: false, width: '490px', tdClass: 'tableStyle',globalSearchDisabled: true,},
        { label: "削除", field: "delbtn", sortable: false, hidden: this.displayDeleteBtn(), width: '100px', tdClass: 'tableStyle',globalSearchDisabled: true,},
        { label: "sid", field: "sid", hidden: true, globalSearchDisabled: true,},
      ],
      rows: [],
      reqParams : {
        startdt: null,
		    enddt: null,
		    companyid: null,
		    callerid: null,
		    calltype: null,
      }
    };
  },
  components: {
    VueGoodTable,
    Loading,
  },
  async created() {
    //初っ端は1時間分を取ってくる
    const startdt = moment().add(-1, 'hours').format('YYYY-MM-DD HH:mm:ss')
    const enddt = moment().format('YYYY-MM-DD HH:mm:ss')
    let companyid = null
    if (this.$store.getters.role != 'super') {
      //全権限は全顧客のを取ってくるようにするため
      companyid = this.$store.getters.companyid
    }
    this.setReqParams(startdt, enddt, companyid, null, null)
    this.getMonitorToTable()
  },
  mounted: function(){
      bus.$on('repaintTable', this.repaintTable)
  },
  async destroyed () {
    bus.$off('repaintTable')
  },
  methods: {
      calltypeFn: function(val) {
        if (val == 'IN') {
          return '着信'
        } else {
          return '発信'
        }
      },
      calleridFn: function(val) {
        if (val == 'anonymous') {
          return '非通知'
        }
        return val
      },
      //table event.
      evDeleteBtn: async function(e) {
        if (!e.sid) {
          await this.$confirm.alert("録音データの削除に失敗しました。")
          return
        }
        let msg = ''
        if (e.monitordt && e.callerid) {
          msg = '選択した以下のデータを削除してもよろしいですか？<br>録音日時: ' + e.monitordt + '<br>発信者番号: ' + e.callerid
        } else {
          msg = '選択したデータを削除してもよろしいですか？ '
        }
        const okFlg = await this.$confirm.confirm(msg)
        // APIにdelete(delflg=1)してもらって、テーブルを更新する
        if (okFlg) {
          this.deleteMonitorToTable(e.sid)
        }

      },
      repaintTable: function(params) {
          //API実行と描画
          this.setReqParams(params.startdt, params.enddt, params.companyid, params.callerid, params.calltype)
          this.getMonitorToTable()
      },
      //$storeを参照するにはfunction()としなければならないらしい
      displayDeleteBtn: function() {
          if (this.$store.getters.role == 'super' || this.$store.getters.role == 'admin') return false
          return true
      },
      displayCompayidProc: function() {
          if (this.$store.getters.role == 'super') return false
          return true
      },
      setReqParams: function(startdt, enddt, companyid, callerid, calltype) {
        //関数跨いで、パラメータを使いまわすことがあるためdataとして持つ
        //セットする前はパラメータを一旦リセット
        this.reqParams = {
          startdt: null,
          enddt: null,
          companyid: null,
          callerid: null,
          calltype: null,
        }
        //パラメータセット
        this.reqParams = {
          startdt: startdt,
          enddt: enddt,
          companyid: companyid,
          callerid: callerid,
          calltype: calltype,
        }
      },
      getMonitorToTable: async function() {
        this.showLoading = true
        const getPbxMonitorList = await this.$api.getPbxMonitor(this.reqParams, this.$store.getters.idToken)
        // 2022.02.02 サーバ上の仕様により、データサイズによって直接レスポンスが来る場合とS3経由でデータを取得する場合がある
        // s3urlパラメータを参照すること
        if (getPbxMonitorList.success && getPbxMonitorList.status == 200 && !getPbxMonitorList.items.s3url) {
          //通常のレスポンスが来る
          this.rows = []
          getPbxMonitorList.items.items.forEach((v) => {
            const row = {
              companyid: v.companyid,
              calltype: v.calltype,
              callerid: v.callerid,
              monitordt: v.monitordt,
              filelocation: '<audio src="' + v.filelocation +'" style="height: 38px;width: 600px;" controls>',
              sid: v.sid,
            }
            this.rows.push(row)
          })
        } else if (getPbxMonitorList.success && getPbxMonitorList.status == 200 && getPbxMonitorList.items.s3url) {
          //S3から取る場合
          this.rows = []
          const s3Path = getPbxMonitorList.items.items[0].path
          const s3Data = await this.$api.getPbxMonitorByS3(s3Path)
          if (s3Data && s3Data.success && s3Data.status == 200) {
            s3Data.items.items.forEach((v) => {
              const row = {
                companyid: v.companyid,
                calltype: v.calltype,
                callerid: v.callerid,
                monitordt: v.monitordt,
                filelocation: '<audio src="' + v.filelocation +'" style="height: 38px;width: 600px;" controls>',
                sid: v.sid,
              }
              this.rows.push(row)
            })
          } else {
            await this.$confirm.alert("録音データの取得に失敗しました。")
          }
        } else {
          await this.$confirm.alert("録音データの取得に失敗しました。")
        }
        this.showLoading = false
      },
      deleteMonitorToTable: async function(sid) {
        this.showLoading = true
        const delParams = {
          sid: sid,
        }
        const getPbxMonitorList = await this.$api.deletePbxMonitor(delParams, this.$store.getters.idToken)
        if (getPbxMonitorList.success && getPbxMonitorList.status == 200) {
          await this.$confirm.alert("録音データを削除しました。")
          await this.getMonitorToTable()
        } else {
          await this.$confirm.alert("録音データの削除に失敗しました。")
        }
        this.showLoading = false
      },
  }
};

/* eslint-enable */
</script>

<style>
div.tablezone {
  height: 100%;
  width: 80%;
  float: left;
}
.tableStyle {
    font-size: 19px;
}
table.vgt-table td {
    padding: 4px;
    vertical-align:inherit;
    border-bottom:inherit;
    color: inherit;
}
.btnDelete {
    background-color: rgb(209, 97, 97);
}
</style>
