<template>
  <div class="headerzone">
    <label>{{ displayCompany }}</label>
    <button id="manargebtn" class="contents" v-if="this.$store.getters.role == 'super' || this.$store.getters.role == 'admin'" v-on:click="goManage()">管 理</button>
    <button id="logoutbtn" class="contents" v-on:click="logout()">ログアウト</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      displayCompany: '',
    };
  },
  created() {
    let role = ''
    if (this.$store.getters.role == 'super') {
      role = '(全権限)'
    } else if (this.$store.getters.role == 'admin') {
      role = '(管理者)'
    } else if (this.$store.getters.role == 'user') {
      role = '(一般)'
    }

    let companyname = ''
    if (this.$store.getters.companyname != null && this.$store.getters.companyname != '') {
       companyname = this.$store.getters.companyname + ' 様'
    }

    this.displayCompany = companyname + role
  },
  methods : {
    logout() {
      clearInterval(this.$store.getters.tokenTimer)
      this.$cognito.logout()
      this.$store.commit("resetAll")
      this.$router.push("/").catch((err) => {})
      return
    },
    goManage() {
      this.$router.push("/Manage").catch((err) => {})
      return
    }
  }
}
/* eslint-enable */
</script>

<style>
/* test css */
div.headerzone {
  height: 100%;
  text-align: right;
}
button.contents {
  font-size: 17px;
  font-weight: bold;
  height: 30px;
  width: 120px;
  margin: 10px;
}
button#manargebtn {
    background-color: rgb(156, 219, 119)
}

button#logoutbtn {
    background-color: rgb(196, 189, 189)
}
</style>
