/* eslint-disable */
import axios from 'axios'

export default class Api {
    configure(config) {
        this.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    }

    static install = (Vue, options) => {
        Object.defineProperty(Vue.prototype, '$api', {
            get() { return this.$root._api }
        })

        Vue.mixin({
            beforeCreate() {
                if (this.$options.api) {
                    this._api = this.$options.api
                    this._api.configure(options)
                }
            }
        })
    }

    async getPbxMonitor(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'get',
                url: '/api/v1/getpbxmonitor',
                headers: headers,
                params: params,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async getPbxMonitorByS3(url) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }

        try {
            const res = await axios({
                method: 'get',
                url: url,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async deletePbxMonitor(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'put',
                url: '/api/v1/deletepbxmonitor',
                headers: headers,
                params: params,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async getCompany(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'get',
                url: '/api/v1/company',
                headers: headers,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async postCompany(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'post',
                url: '/api/v1/company',
                headers: headers,
                data: params,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async getUserList(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'get',
                url: '/api/v1/accountuser',
                headers: headers,
                params: params,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async postUser(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'post',
                url: '/api/v1/accountuser',
                headers: headers,
                data: params,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }

    async putUser(params, token) {
        let resObj = {
            success: true,
            status: 200,
            items: null,
        }
        let headers = this.headers
        headers.Authorization = token

        try {
            const res = await axios({
                method: 'put',
                url: '/api/v1/accountuser',
                headers: headers,
                data: params,
            })
            resObj.status = res.status
            resObj.items = res.data
        } catch (err) {
            const {
                status,
            } = err.response;
            resObj.success = false
            resObj.status = status
        }

        return resObj
    }
}

/* eslint-enable */