<template>
<div id="pbxmonitor">
  <div id="webheader">
    <HeaderMonitor />
  </div>
  <div id="webcontents">
    <SearchMonitor />
    <TableMonitor />
  </div>
</div>
</template>

<script>
/* eslint-disable */
import HeaderMonitor from "@/components/HeaderMonitor.vue";
import SearchMonitor from "@/components/SearchMonitor.vue";
import TableMonitor from "@/components/TableMonitor.vue";
import moment from "moment";

export default {
  data() {
    return {
    };
  },
  components: {
    HeaderMonitor,
    SearchMonitor,
    TableMonitor,
  },
  beforeCreate() {
    //前のトークン取得タイマー処理がある場合はリセットする
    if (this.$store.getters.tokenTimer) {
      clearInterval(this.$store.getters.tokenTimer)
    }
    //直でこのパスに来てもトークンが不正であればログイン画面に戻るページに遷移
    if (
      this.$store.getters.idToken == null ||
      this.$store.getters.idToken == "" ||
      this.$store.getters.accessToken == null ||
      this.$store.getters.accessToken == "" ||
      this.$store.getters.refreshToken == null ||
      this.$store.getters.refreshToken == "" ||
      this.$store.getters.companyid == null ||
      this.$store.getters.companyid == "" ||
      this.$store.getters.role == null ||
      this.$store.getters.role == ""
    ) {
      this.$router.push("/Redirect").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
      return
    }
    //トークン有効期限が切れているor切れる10分前もNG
    const now = moment();
    const exp = moment(this.$store.getters.exp, "X");
    if (exp.diff(now, "minutes") < 10) {
      this.$router.push("/Redirect").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
      return
    }
    //1分おきにトークンの有効期限を確認して、期限が過ぎていればトークンを取り直す処理
    const timerId = setInterval(() => {
      const rNow = moment()
      const rExp = moment(this.$store.getters.exp, "X")
      if (rExp.diff(rNow, "minutes") < 10) {
        //10分切ったらトークンを更新する
        this.getToken()
      }
    }, 60000)
    this.$store.commit("setTokenTimer", timerId);
  },
  methods: {
    async getToken() {
      const newToken = await this.$cognito.updateToken(this.$store.getters.refreshToken)
      if (!newToken.success) {
        await this.$confirm.alert("サーバにてエラーが発生しました。お手数ですが、再ログインしてください。")
        this.$router.push("/").catch((err) => {}); //NavigationDuplicatedエラーは握りつぶすのがいいらしい・・・
        return
      }
      //stoireに一式格納
      this.$store.commit("setIdToken", newToken.auth.idToken);
      this.$store.commit("setAccessToken", newToken.auth.accessToken);
      this.$store.commit("setRefreshToken", newToken.auth.refreshToken);
      this.$store.commit("setExp", newToken.auth.exp);
    },
  },
};
/* eslint-enable */
</script>

<style>
div#pbxmonitor {
  height: 100vh;
  width: 100vw;
}
div#webheader {
  height: 5vh;
}
div#webcontents {
  height: 95vh;
}
</style>